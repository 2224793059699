import axios from 'axios';
import { authUser } from './userService';

const API_URL = 'https://api.murmuratto.com';

const getUsers = async () => {
  const loggedUser = authUser();
  const accessToken = loggedUser.accessToken;

  try {
    const response = await axios.get(`${API_URL}/administration/users`, {
      headers: {
        'Authorization': `Bearer ${accessToken}`,
        'Content-Type': 'application/json',
      }
    });
    return response.data;
  } catch (error) {
    console.error('Error fetching users:', error.response ? error.response.data : error.message);
    throw error;
  }
};

const exportUsers = async () => {
    const loggedUser = authUser();
    const accessToken = loggedUser.accessToken;
  
    try {
      const response = await axios.get(`${API_URL}/administration/users?action=export`, {
        headers: {
          'Authorization': `Bearer ${accessToken}`,
          'Content-Type': 'application/json',
        },
      });
      return response.data; // You can handle the blob (file) appropriately here
    } catch (error) {
      console.error('Error exporting users:', error.response ? error.response.data : error.message);
      throw error;
    }
  };

  // Create a new user with form data
const createUser = async (userData) => {
  const loggedUser = authUser();
  const accessToken = loggedUser.accessToken;
  
  const formData = new FormData();
  formData.append('firstName', userData.firstName);
  formData.append('lastName', userData.lastName);
  formData.append('email', userData.email);
  if(userData.departmentId) formData.append('departmentId', userData.departmentId);
  if(userData.supervisorId) formData.append('supervisorId', userData.supervisorId);
  if(userData.secondaryEmail) formData.append('secondaryEmail', userData.secondaryEmail);

  if (Array.isArray(userData.rolesIds)) {
    userData.rolesIds.forEach(roleId => formData.append('rolesIds', roleId));
  } else {
    formData.append('rolesIds', []);
  }

  if (Array.isArray(userData.privilegesIds)) {
    userData.privilegesIds.forEach(privilegeId => formData.append('privilegesIds', privilegeId));
  } else {
    formData.append('privilegesIds', []);
  }
  try {
    const response = await axios.post(`${API_URL}/administration/users`, formData, {
      headers: {
        'Authorization': `Bearer ${accessToken}`,
        'Content-Type': 'multipart/form-data',
      },
    });
    return response.data;
  } catch (error) {
    console.error('Error creating user:', error.response ? error.response.data : error.message);
    throw error;
  }
};


const updateUser = async (userData) => {
  const loggedUser = authUser();
  const accessToken = loggedUser.accessToken;
  console.log(userData);

  // Create a new FormData object
  const formData = new FormData();

  if (userData.userId) formData.append('userId', userData.userId);
  if (userData.firstName) formData.append('firstName', userData.firstName);
  if (userData.lastName) formData.append('lastName', userData.lastName);
  if (userData.email) formData.append('email', userData.email);
  if (userData.departmentId) formData.append('departmentId', userData.departmentId);
  if (userData.supervisorId) formData.append('supervisorId', userData.supervisorId);
  if (userData.secondaryEmail) formData.append('secondaryEmail', userData.secondaryEmail);

  // Assuming rolesIds is an array, we'll append each role to form data
  if (Array.isArray(userData.rolesIds)) {
    userData.rolesIds.forEach(roleId => formData.append('rolesIds', roleId));
  } else {
    formData.append('rolesIds', []);
  }

  if (Array.isArray(userData.privilegesIds)) {
    userData.privilegesIds.forEach(privilegeId => formData.append('privilegesIds', privilegeId));
  } else {
    formData.append('privilegesIds', []);
  }

  try {
    const response = await axios.patch(`${API_URL}/administration/users`, formData, {
      headers: {
        'Authorization': `Bearer ${accessToken}`,
        'Content-Type': 'multipart/form-data',
      }
    });

    return response.data;
  } catch (error) {
    console.error('Error updating user:', error.response ? error.response.data : error.message);
    throw error;
  }
};


const deleteUser = async (userId, assignments) => {
  const loggedUser = authUser();
  const accessToken = loggedUser.accessToken;

  const formData = new FormData();
  formData.append('userId', userId);

  assignments.forEach((assignment) => {
    const { item, res, type, role } = assignment;

    switch (type) {
      case 'Opportunity':
          if (role === 'Proponent') 
            formData.append(`opportunityProponent[${item.id}]`, res.value);
          else if (role === 'Responsible') 
            formData.append(`opportunityResponsible[${item.id}]`, res.value);
          else if (role === 'Coach') 
            formData.append(`coach[${item.id}]`, res.value);
        break;
      case 'Committee':
        formData.append(`evaluationCommittee[${item.id}]`, res.value);
        break;
      case 'Department':
        formData.append(`department[${item.id}]`, res.value);
        break;
      default:
        console.warn(`Unknown assignment type: ${type}`);
        break;
    }
  });

  try {
    const response = await axios({
      method: 'delete',
      url: `${API_URL}/administration/users`,
      data: formData,
      headers: {
        'Authorization': `Bearer ${accessToken}`,
        'Content-Type': 'multipart/form-data',
      },
    });

    return response.data;
  } catch (error) {
    throw error;
  }
};


const getUserAssignments = async (userId) => {
  const loggedUser = authUser(); // Assuming you have an authUser() function
  const accessToken = loggedUser.accessToken;

  try {
    const response = await axios.get(`${API_URL}/administration/users/${userId}/assignments`, {
      headers: {
        'Authorization': `Bearer ${accessToken}`,
      }
    });
    
    return response.data;
  } catch (error) {
    console.error('Error fetching user assignments:', error.response ? error.response.data : error.message);
    throw error;
  }
};



export default {
  getUsers,
  exportUsers,
  createUser,
  updateUser,
  deleteUser,
  getUserAssignments
};
