import moment from 'moment';
import { object } from "prop-types";
import React from "react";
import ReactDOM from 'react-dom';
import ReactDOMServer from 'react-dom/server';
import Select from 'react-select';
import {
  Button,
  Col,
  Form,
  FormInput,
  Row,
  Tooltip
} from "shards-react";
import Swal from 'sweetalert2';
import { users as UserListNew } from '../../components/components-overview/UserListNew';
import { authUser } from "../../services/userService";
import ExecutionSelectNew from "./ExecutionSelectNew";
import SupervisorSelect from "./SupervisorSelect";

import { ReactComponent as AcceptIcon } from "../../images/accept_button.svg";
import { ReactComponent as CancelIcon } from "../../images/cancel_button.svg";
import { ReactComponent as DollarImage } from "../../images/dollar-symbol.svg";
import { ReactComponent as HandImage } from "../../images/hand.svg";
import { ReactComponent as InfoIcon } from "../../images/info_icon.svg";
import { ReactComponent as ChartImage } from "../../images/line-chart.svg";
import { ReactComponent as RedIcon } from "../../images/red_icon.svg";
import { ReactComponent as ShieldImage } from "../../images/shield.svg";
import { ReactComponent as TeamImage } from "../../images/team.svg";
import { ReactComponent as TimeImage } from "../../images/time.svg";

// New
import { ReactComponent as UrgentImage } from '../../images/Icons_Idle_01_Urgent.svg';
import { ReactComponent as ProductivityImage } from "../../images/Icons_Idle_02_Productivity.svg";
import { ReactComponent as TrophyImage } from "../../images/Icons_Idle_04_Trophy.svg";
import { ReactComponent as Shield2Image } from "../../images/Icons_Idle_05_Shield.svg";
import { ReactComponent as DollarSignImage } from "../../images/Icons_Idle_06_Dollar Sign.svg";
import { ReactComponent as NumberOneImage } from "../../images/Icons_Idle_07_Number One.svg";
import { ReactComponent as CheckmarkImage } from "../../images/check1.svg";
import selectIdeaImage from '../../images/selected.png';

// New Selected
import { ReactComponent as UrgentImageSelected } from '../../images/Icons_Selected_01_Urgent.svg';
import { ReactComponent as ProductivityImageSelected } from "../../images/Icons_Selected_02_Productivity.svg";
import { ReactComponent as TrophyImageSelected } from "../../images/Icons_Selected_04_Trophy.svg";
import { ReactComponent as Shield2ImageSelected } from "../../images/Icons_Selected_05_Shield.svg";
import { ReactComponent as DollarSignImageSelected } from "../../images/Icons_Selected_06_Dollar Sign.svg";
import { ReactComponent as NumberOneImageSelected } from "../../images/Icons_Selected_07_Number One.svg";
import { ReactComponent as CheckmarkImageSelected } from "../../images/check1_selected.svg";

import Switch from "./Switch.js";


import { withTranslation } from 'react-i18next';

import administrationService from '../../services/administrationService.js';
import { sendResetEmail } from '../../services/emailService.js';
import DepartmentSelect from "./DepartmentSelect";
import SelectPrivileges from "./SelectPrivileges";

class EditUserForm extends React.Component {
    constructor(props) {
        super(props);

        // Refs
        this.galleryRef = React.createRef();

        this.state = {
          data:[],
          ideaQuestions: [],
          answers:[],
          category:null,
          department:null,
          date: '',
          categoryQuestions: [],
          filterQuestions: [],
          selectedFilterQ: [],
          filterQAnswers: [],
          categoryQAnswers: [],
          ideaDescription: null,
          file:null, 
          buttonNext:true,
          descriptionValid:'',
          remainingCharacters: 250,
          visible: true,
          filterVisible: false,
          filterQuestionsVisible: false, 
          ideaQuestionsVisible: false,
          hideNextButton: false,
          userName: 'User Name',
          sectionTitle:'',
          formButtonTitle: 'Continuar',
          ideaNumber: '#00008',
          status: '',
          executionResObj: object,
          selectedBenefit:'',
          selectedResult: '',
          money: '',
          selectedImprovement: '',
          selectedImpact: '',
          selectedCoachBackup: '',
          isRecognized: '',
          page: 2,
          responseInfo: false,
          responseInfo2: false,
          selectedStatus: ''.length,
          expectedReturn: '',
          timeUnit: '',
          language: 'en',
          executionRes: 0,
          coachRes: '',
          recurringImpact: false,
          comment: '',
          categoryDuration: false,
          startDate: '',
          endDate: '',
          selectedLanguage: {
            value:'English',
            label:'English'
          }, 
          categoryTitle: '',
          categoryInformation: '',
          hasEnglish: false,
          hasSpanish: false,
          firstName: '',
          lastName: '',
          email: '',
          password: '',
          privileges: [],
          userData: '',
          canEdit: '',
          accountDisabled: false,
          roles: [], 
          supervisor: '',
          supervisorSelected: null,
          secondaryEmail: '',
          activeTab: 'departments '
        }

        this.change = this.change.bind(this);
        this.setCategory = this.setCategory.bind(this);
        this.setDate = this.setDate.bind(this);
        this.setIdeaDescription = this.setIdeaDescription.bind(this);
        this.selectFile = this.selectFile.bind(this);
        this.showNext = this.showNext.bind(this);
        this.addAnswer = this.addAnswer.bind(this);
        this.saveIdea = this.saveIdea.bind(this);
        this.showNext = this.showNext.bind(this);
        this.changeMoney = this.changeMoney.bind(this);
        this.downloadFile = this.downloadFile.bind(this);
        this.setEvalStatus = this.setEvalStatus.bind(this);
        this.setFirstName = this.setFirstName.bind(this);
        this.setEmail = this.setEmail.bind(this);
        this.setSecondaryEmail = this.setSecondaryEmail.bind(this);
        // this.setPassword = this.setPassword.bind(this);
        this.setLanguage = this.setLanguage.bind(this);

    }

    componentDidUpdate(prevProps) {
      if (prevProps.userData !== this.props.userData) {
        // alert('Can evaluate!')
        this.loadInitialSettings()
      }
    }

    componentDidMount() {
      this.loadInitialSettings()
      this.getDate();
    }

    loadInitialSettings() {
      const { userData } = this.props;
      if (userData) {
        const firstName = userData.firstName
        const lastName = userData.lastName
        const email = userData.email
        const accountDisabled = userData.accountDisabled
        const secondaryEmail = userData.secondaryEmail
        const supervisor = userData.supervisor
        const department = userData.department
        const selectedDepartment = {value: department.id, label: department.title.en}
   
        this.setState({
          supervisorSelected: (supervisor ? supervisor.id : null),
          firstName: firstName,
          lastName: lastName,
          email: email,
          department: selectedDepartment,
          roles: userData.roles.filter((r) => r !== 'user'),
          privileges: userData.privileges,
          canEdit: true,
          userData: userData,
          accountDisabled: accountDisabled,
          secondaryEmail: secondaryEmail
        })
      }
    }

    getDate() {
      this.setState({
        date: new Date()
      });
    }

    async getUserName(user) {
      // var query = new Parse.Query(Parse.User);
      // query.equalTo("objectId", user.id);
      // const results = await query.find();
      // const firstName = results[0].get('firstName');
      // const lastName = results[0].get('lastName');
      // const fullName = firstName + ' ' + lastName;
      // this.setState({
      //   userName: fullName
      // });
    }

    handleSubmit() {
      const { formButtonTitle } = this.state;
      
      if (formButtonTitle == "Continuar") {
        this.showNext();
      } else {
        var isInvalid = false
        this.state.answers.forEach((item,idx) => {
          //  
          if (item.required && !item.answer) {
            isInvalid = true
          }
        });

        if (isInvalid) {
          alert("Por favor conteste todas las preguntas requeridas.");
        } else {
        alert("Su IDEA ha sido sometida.");
      }
    }
  }

    showNext() {
      const isShowingForm = this.state.visible;
      const isShowingFilter = this.state.filterVisible;
      const isShowingFilterQuestions = this.state.filterQuestionsVisible;
      const isShowingQuestions = this.state.ideaQuestionsVisible;

      if (isShowingForm && !isShowingFilter && !isShowingFilterQuestions && !isShowingQuestions) {

        const { category, department, descriptionValid } = this.state;

        if (!category || !department || !descriptionValid) {
          alert("Por favor seleccione una categoria, un departamento y asegurese de que la descripcion de la idea sea valida.");
        } else {
          this.setState({
            visible: !isShowingForm,
            filterVisible: !isShowingFilter,
            buttonState: false,
            hideNextButton: true,
          });
        }
      } else if (!isShowingForm && isShowingFilter && !isShowingFilterQuestions && !isShowingQuestions) {
        this.setState({
          hideNextButton: false,
          filterVisible: !isShowingFilter,
          filterQuestionsVisible: !isShowingFilterQuestions,
          buttonState: false,
        });
      } else if (!isShowingForm && !isShowingFilter && isShowingFilterQuestions && !isShowingQuestions) {
        var allAnswered = false;
        //  
        //  
        var isInvalid = false
        this.state.filterQAnswers.forEach((item,idx) => {
          //  
          if (!item.answer) {
            isInvalid = true
          }
        });

        if (isInvalid) {
          alert("Por favor conteste todas las preguntas.");
        } else {
          this.setState({
            filterQuestionsVisible: !isShowingFilterQuestions,
            ideaQuestionsVisible: !isShowingQuestions,
            buttonState: false,
            formButtonTitle: 'Someter',
          });
        }
      } else if (!isShowingForm && !isShowingFilter && !isShowingFilterQuestions && isShowingQuestions) {
        //  
        var isInvalid = false
        this.state.answers.forEach((item,idx) => {
          //  
          if (item.required && !item.answer) {
            isInvalid = true
          }
        });

        if (isInvalid) {
          alert("Please answer all required questions.");
        } else {
          alert('¡Congrats! Thanks for submitting your idea.', 
          this.resetIdeaForm());
        }
      }
      //  
      //  
      //  
      //  
      //  
    }

    resetIdeaForm() {
      window.location.reload();
    }


   

    

    handleFilterChange(newFilter) {
      const newCategory = newFilter;
      const { filterQuestions, selectedFilterQ } = this.state;
      //  
      //  
      
      var filteredData;
      if (newCategory !== 'Todas') {
        filteredData = filterQuestions.filter(item => item.get("filter") === newCategory);
      }  else {
        filteredData = filterQuestions;
      }
      
      this.setState({
        selectedFilterQ: filteredData
      }, this.addFilterAnswer(filteredData));
      //  
      this.showNext();
    }

    handleCategoryChange(selectedCategory) {
      const newCategory = selectedCategory;
      const { ideaQuestions } = this.state;

      var filteredData = ideaQuestions.filter(item => item.get("category") === newCategory );
            
      this.setState({
        categoryQuestions: filteredData
      }, this.addAnswer(filteredData));
    }

    onSubmit = e => {
      e.preventDefault();
      alert('Form submitted');
    };

    saveIdea() {
      // // Simple syntax to create a new subclass of Parse.Object.
      // var Idea = Parse.Object.extend("Idea");
      // // Create a new instance of that class.
      // var ideaInfo = new Idea();

      // const currentUser = Parse.User.current();
      // const userId = currentUser.get("username");
    
      // // IDEA Properties
      // ideaInfo.set("proponent", userId);
      // ideaInfo.set("department",this.state.department);
      // ideaInfo.set("category", this.state.category);
      // ideaInfo.set("date", this.state.date);
      // ideaInfo.set("description", this.state.ideaDescription);
      // ideaInfo.set("file", this.state.file);
      // ideaInfo.set("status", "saved");
      // ideaInfo.set("progress", [0,100]);
      
      // // ideaInfo.save()
      // // .then((ideaInfo) => {
      // //   // Execute any logic that should take place after the object is saved.
      // //   this.resetForm();
      // //   alert('Su IDEA fue guardada exitosamente.');
      // // }, (error) => {
      // //   // Execute any logic that should take place if the save fails.
      // //   // error is a Parse.Error with an error code and message.
      // //   alert('Failed to create new object, with error code: ' + error.message);
      // // });
    }

    resetForm() {
      this.setState({proponent: '', department: '', category: '', ideaDescription:'', date: new Date(),file: '', remainingCharacters: 250, descriptionValid:''});
    }

    change(event) {
      // this.setState({category: event.target.value});
      this.setState({department: event.target.value});
      //  
    }

    setCategory(categoryName) {
      this.setState({
        category: categoryName
      })

      this.handleCategoryChange(categoryName)
      //  
    }

    setFilter(filterName) {
      //  
      // this.setState({sectionTitle: (filterName === "innovacion")?"INNOVACION":"RESOLUCION DE PROBLEMAS"});
      this.handleFilterChange(filterName);
    }

    clickedPrint() {
       
    }

    setDate(ideaDate) {
      this.setState({
        date: ideaDate
      })
      //  
    }

    filterQuestionAnswerChange(event, idx) {
      //  
      //  
      // const newObj = {'question':this.state.filterQuestions[idx], 'answer': event.target.value }
      this.state.filterQAnswers[idx].answer = event.target.value;
      //  
      // const newArray = this.state.filterQAnswers
      // this.setState({filterQAnswers: newArray},  
      // this.setState({department: event.target.value});
      //  
    }

    questionAnswerChange(event, idx) {
      //  
      //  
      // const newObj = {'question':this.state.filterQuestions[idx], 'answer': event.target.value }
      this.state.answers[idx].answer = event.target.value;
      //  
      // const newArray = this.state.filterQAnswers
      // this.setState({filterQAnswers: newArray},  
      // this.setState({department: event.target.value});
      //  
    }

    setIdeaDescription(event) {
      const description = event.target.value;
      const maxCharacters = 250;
      const charCount = maxCharacters - description.length
      var isValid = null;

      if (charCount < 0 && description.length > 0) {
        isValid = false
      } else if (charCount > 0 && description.length > 0) {
        isValid = true
      } else {
        isValid = null
      }

      //  
      this.setState({
        descriptionValid: isValid,
        ideaDescription: description,
        remainingCharacters: charCount
      })
      //  
    }

    selectFile(file) {
      //  
    }

    addAnswer(filteredQuestions) {
      filteredQuestions.map((item, idx) => (
        //  
        this.setState((prevState) => ({
          answers: [...prevState.answers, {question:item.get("question"), answer:"", required: item.get("required")}],
        }))
      ))
      //  
    }

    addFilterAnswer(filteredData) {
      //  
    
      var newItems = [];
      filteredData.forEach((item, idx) => {
        newItems.push({question:item.get("question"), answer:''});
      })

      this.setState({ filterQAnswers: newItems}, () => {
          //  
        });
    }

    submitEvaluation() {
      // const {status, ideaDescription, descriptionValid, userName, committeeResObj, executionRes} = this.state;
      // const { setFinishedSaving } = this.props;

      // var ideaItem = this.props.ideaItem;
      // var comments = ideaItem.get("comments");
      // var newStatus = '';
      // var percentage = [25,75];
      // var mayNeedEval = false 
      
      // // Verify idea to check if it leaves the evaluation inbox or not
      // if (status == 'Proyecto' || status == 'Otro') {
      //   mayNeedEval = true
      // }
      
       
       

      // switch(status) {
      //   case "Devuelta":
      //     // code block
      //     newStatus = 'Idea Devuelta - Mas Informacion';
      //     break;
      //   case "Espera":
      //     // code block
      //     newStatus = 'Idea en Espera';
      //     break;
      //   case "No Perseguido":
      //     // code block
      //     newStatus = 'No Perseguido';
      //     percentage = [0,100];
      //     break;
      //   case "Ejecutar":
      //     // code block
      //     newStatus = 'Ejecutar - Just Do It';
      //     break;

      //   case "Ejecutar Proyecto":
      //     // code block
      //     newStatus = 'Ejecutar - Just Do It - Proyecto';
      //     break;
      //   case "Proyecto":
      //     // code block
      //     newStatus = 'Idea Proyecto';
      //     percentage = [0,100];
      //     break;
      //   default:
      //     newStatus = 'SOMETIDA';
      //     percentage = [0,100];
      //     // code block
      // }



      // // Comments
      // var newComment = {"comment": ideaDescription, "date": new Date(), "user":userName, "progress":[25,75]};

      // if (ideaDescription && ideaDescription.length > 0) {
      //   comments.push(newComment)
      // }
       
      // const responsibleName = this.state.executionRes.label;
      // const resUser = this.state.executionRes;

      // ideaItem.set("status", newStatus);
      // ideaItem.set("comments", comments);
      // ideaItem.set("needsEvaluation", mayNeedEval);
      // ideaItem.set("progress", percentage);

      // if (status == 'Otro') {
      //   var otherCom = committeeResObj.get('value');
      //   ideaItem.set("department", otherCom);
      // } else {
      //   ideaItem.set("responsibleName",responsibleName);
      // }

      // ideaItem.set("responsible", resUser.value);
      // ideaItem.set("edited", false);
      // ideaItem.set("customUpdatedAt", new Date());
       
      // if ( newStatus == 'No Perseguido') {
      //   if (window.confirm('Did you have a conversation with your employee? If you did, please click OK.')) this.saveIdeaItem(ideaItem) 
      // } else {
      //   this.saveIdeaItem(ideaItem) 
      // }
  }

  saveIdeaItem(ideaItem) {
    // const { setFinishedSaving } = this.props;
    // Parse.Object.saveAll([ideaItem], {useMasterKey: true}).then(() => {
    //   if (this.state.status == 'Otro') {
    //     alert('Su evaluacion ha sido sometida. ¡Gracias!', setFinishedSaving());
    //   } else {
    //     setFinishedSaving()
    //   }
    // });
  }

    changeStatus(selection) {
      this.setState({status: selection});
       
    }

    // changeResponsible(res, idx) {
    //    
    //   this.setState({
    //     executionResObj: res,
    //     executionRes: idx
    //   });
    //    
    // }

    changeEvaluationResponse() {
      // const responseObj = {
      //   status: '',
      //   economicImpact: '',
      //   timeFrame: '',
      //   recurringImpact: false,
      //   comments: '',
      //   ideaOwner: '',
      //   ideaCoach: '',
      //   privileges: '',
      //   supervisor: ''
      // }
    }

    async getHumanResourceUsers() {
      // var query = new Parse.Query(Parse.User);
      // query.equalTo("humanResources", true);
      // const results = await query.find();
       
      // return results
    }

    async setVerificationNotifications() {
      // const allUsers= await this.getHumanResourceUsers();
      // for (var u in allUsers) {
      //   allUsers[u].increment("notificationCount");
      //   var responsibleNotifications = allUsers[u].get("notificationMessages");
      //   responsibleNotifications.unshift("'¡Congrats! You have ideas to evaluate.'");
      //   allUsers[u].set("notificationMessages", responsibleNotifications);
      //   allUsers[u].set("customUpdatedAt", new Date());
      //   await allUsers[u].save().catch(error => {
      //     // This will error, since the Parse.User is not authenticated
      //   });
      // }
    }

    async setNotifications() {
      // const {allUsers, department, category} = this.state;

      // const responsibleMsg = '¡Congrats! Your idea has been verified.';

      // var ideaItem = this.props.ideaItem;

      // var responsibleUser = ideaItem.get("responsible");

      // var responsibleNotifications = responsibleUser.get("notificationMessages");
      // responsibleNotifications.unshift(responsibleMsg);

      // responsibleUser.increment("notificationCount");
      // responsibleUser.set("notificationMessages", responsibleNotifications);
      // responsibleUser.set("customUpdatedAt", new Date());

      // var users = new Parse.Query("Users");

      // if(this.state.isRecognized) {
      //   await Parse.Object.saveAll(users, {useMasterKey: true}).then(()=> this.setVerificationNotifications())
      //   .catch(error => {
           
      //  });
      // } else {
      //   await Parse.Object.saveAll(users, {useMasterKey: true})
      //   .catch(error => {
           
      //  });
      // }
    }

    changeBenefit(response) {
        this.setState({
          selectedBenefit: response
        });
      }

    changeResults(response) {
    this.setState({
        selectedResult: response
    });
    }

    changeImprovement(response) {
    this.setState({
        selectedImprovement: response
    });
    }

    changeMoney(response) {
    this.setState({
        money: response
    });
    }

    changeImpact(response) {
    this.setState({
        selectedImpact: response
    });
    }

    changeBackup(response) {
    this.setState({
        selectedCoachBackup: response
    });
    }

    changeMoney(response) {
      const inputMoney = parseFloat(response.target.value);
       
      this.setState({
        money: inputMoney
      })
    }

    changeRecognition(response) {
      this.setState({
          isRecognized: response
      });
      }

    downloadFile(file) {
        //  
        if (file != null) {
            const newWindow = window.open(file._url, '_blank', 'noopener,noreferrer')
            if (newWindow) newWindow.opener = null
        } else {
            alert('No file found...');
        }
    }

    getDate(date) {
      return moment(date).format('DD / MM / YYYY');
    }

    getIcon(name, fillColor) {
      const newIcons = [
          {normal: <UrgentImage className="mr-auto d-block" style={{minWidth: 80, maxWidth:80}}/>,
           selected: <UrgentImageSelected className="mr-auto d-block" style={{minWidth: 80, maxWidth:80}}/>
          },
          {normal: <ProductivityImage className="mr-auto d-block" style={{minWidth: 80, maxWidth:80}}/>,
           selected: <ProductivityImageSelected className="mr-auto d-block" style={{minWidth: 80, maxWidth:80}}/>
          },
          {normal: <CheckmarkImage className="mr-auto d-block" style={{minWidth: 80, maxWidth:80}}/>,
           selected: <CheckmarkImageSelected className="mr-auto d-block" style={{minWidth: 80, maxWidth:80}}/>
          },
          {normal: <TrophyImage className="mr-auto d-block" style={{minWidth: 80, maxWidth:80}}/>,
           selected: <TrophyImageSelected className="mr-auto d-block" style={{minWidth: 80, maxWidth:80}}/>
          },
          {normal: <Shield2Image className="mr-auto d-block" style={{minWidth: 80, maxWidth:80}}/>,
           selected: <Shield2ImageSelected className="mr-auto d-block" style={{minWidth: 80, maxWidth:80}}/>
          },
          {normal: <DollarSignImage className="mr-auto d-block" style={{minWidth: 80, maxWidth:80}}/>,
           selected: <DollarSignImageSelected className="mr-auto d-block" style={{minWidth: 80, maxWidth:80}}/>
          },
          {normal: <NumberOneImage className="mr-auto d-block" style={{minWidth: 80, maxWidth:80}}/>,
           selected: <NumberOneImageSelected className="mr-auto d-block" style={{minWidth: 80, maxWidth:80}}/>
          },
      ]

      switch(name) {
          case 'HandImage':
            return <HandImage className="mx-auto d-block" style={{minWidth: 80, maxWidth:80, fill: fillColor}}/>;
          case 'ShieldImage':
              return <ShieldImage className="mx-auto d-block" style={{minWidth: 80, maxWidth:80, fill: fillColor}}/>;
          case 'ChartImage':
              return <ChartImage className="mx-auto d-block" style={{minWidth: 80, maxWidth:80, fill: fillColor}}/>;
          case 'TeamImage':
              return <TeamImage className="mx-auto d-block" style={{minWidth: 80, maxWidth:80, fill: fillColor}}/>;
          case 'DollarImage':
              return <DollarImage className="mx-auto d-block" style={{minWidth: 80, maxWidth:80, fill: fillColor}}/>;
          case 'ClockImage':
              return <TimeImage className="mx-auto d-block" style={{minWidth: 80, maxWidth:80, fill: fillColor}}/>;
          //New Icons
          case 'Urgent':
              return newIcons[0].selected;
          case 'Productivity':
              return newIcons[1].selected;
          case 'Checkmark':
              return newIcons[2].selected;
          case 'Trophy':
              return newIcons[3].selected;
          case 'Shield':
              return newIcons[4].selected;
          case 'Dollar':
              return newIcons[5].selected;
          case 'Number One':
              return newIcons[6].selected;
          case 'Approve':
              return newIcons[0].selected;
          case 'Do not Pursue':
              return newIcons[1].selected;
          case 'Save for Later':
              return newIcons[2].selected;
          case 'Request information':
              return newIcons[3].selected;
          case 'Project Idea':
              return newIcons[4].selected;
          case 'Transfer Committee':
              return newIcons[5].selected;
          default:
            return <img src={selectIdeaImage} width="200" height="200" />//<SelectIdeaImage className="mr-auto d-block" style={{minWidth: 200, maxWidth:200}}/>
        }
  }
    toggle() {
      // alert('hover')
      this.setState({
        responseInfo: !this.state.responseInfo
      });
    }

    setEvalStatus(status) {
       
      this.setState({
        selectedStatus: status
      })
    }

    setFirstName(event) {
      const name = event.target.value;
  
      //  
      this.setState({
        firstName: name,
      })
    }

    setEmail(event) {
      const email = event.target.value;
  
      //  
      this.setState({
        email: email,
      })
    }

    setSecondaryEmail(event) {
      const email = event.target.value;
  
      //  
      this.setState({
        secondaryEmail: email,
      })
    }

    changePassword(event) {
      const password = event.target.value;
  
      //  
      this.setState({
        password: password,
      })
    }

    setLanguage(unit) {
       
      this.setState({selectedLanguage: unit}, () => {
        this.loadInitialSettings()
    })
    }

    changeSupervisor(res, idx) {
      this.setState({
        supervisorSelected: res ? res.value : null,
      });
       
       
    }

    changeDepartment(res, idx) {
        this.setState({
          department: res,
        });
      }

    changePrivileges(res, idx) {
      this.setState({
        privileges: res ? res.map(privilege => privilege.value) : [],
      });
    }

    changeRoles(res, idx) {
      this.setState({
        roles: res ? res.map(rol => rol.value) : [],
      });
    }

    changeCoach(res, idx) {
      this.setState({
        coachRes: res,
      });
       
    }

    changeLastName(res) {
      const lastName = res.target.value
      this.setState({
        lastName: lastName
      })
    }
    
    async saveUser() {
      const { firstName, lastName, email, department, supervisorSelected, secondaryEmail, roles, privileges } = this.state;
    
      // Validate required fields
      if (firstName === '' || lastName === '' || email === '' || (department === '' || !department)) {
        alert('Please enter all required information.');
        return;
      }
    
      try {
        const userData = {
          firstName,
          lastName,
          email,
          departmentId: department ? department.value : null,  
          supervisorId: supervisorSelected || null,
          secondaryEmail: secondaryEmail,
          privilegesIds: privileges, 
          rolesIds: roles,
        };
    
        const createdUser = await administrationService.createUser(userData);
    
        if (createdUser) {
          this.props.refreshUsers();
          alert('User was created successfully.');
        }
      }catch (error) {
        if(error.response.data.error)
          alert(error.response.data.error.message);
        else if(error.response.data.message)
          alert(error.response.data.message);
        else
          alert(error);
      }
    }

    sendResetEmailToUser = async (email, showAlert) => {
      const currentUser = authUser();
      try {
        const sendEmailResponse = await sendResetEmail(email, currentUser.company.code);
        showAlert && alert(sendEmailResponse.message);
       } catch (error) {
        alert(error.message);
       }
    }



    async editUser() {
      const { userData, firstName, lastName, email, secondaryEmail, department,  supervisorSelected, roles, privileges } = this.state;
      if (firstName === '' || lastName === '' || email === '' || !department) {
        alert('Please enter all required information.');
        return;
      }
    
      userData.firstName = firstName;
      userData.lastName = lastName;
      userData.email = email;
      userData.secondaryEmail = secondaryEmail || '';
      userData.departmentId = department ? department.value : null;
      userData.supervisorId = supervisorSelected || null;

      try {
        await administrationService.updateUser({
          userId: userData.id,
          firstName: userData.firstName,
          lastName: userData.lastName,
          email: userData.email,
          departmentId: userData.departmentId,
          supervisorId: userData.supervisorId,
          secondaryEmail: userData.secondaryEmail,
          rolesIds: roles,
          privilegesIds: privileges, 
        });
    
        alert('Your user was edited successfully.');
        this.props.refreshUsers();
      } catch (error) {
        if(error.response.data.error)
          alert(error.response.data.error.message);
        else if(error.response.data.message)
          alert(error.response.data.message);
        else
          alert(error);
      }
    }
    
    

    changeUsername = async() => {
      UserListNew.map((user) => {
        this.changeUserData(user.username, user.email)
      })
    }

    async changeUserData(username, email) {
      // var query = new Parse.Query(Parse.User);
      // query.equalTo("email", email);
      // const results = await query.find();

      // if (results.length > 0) {
      //   const myUser = results[0]
         

      //   //  
      //   myUser.set("username", username)
      //   myUser.set("customUpdatedAt", new Date());
        
      //   Parse.Object.saveAll([myUser], {useMasterKey: true}).then(() => {
           
      //     this.props.refreshUsers()
      //   }).catch((error) => {
           
      //   })
      // }
    }

     // Function to change the active tab
    setActiveTab = (tabName) => {
      this.setState({ activeTab: tabName });
    }

    renderTabContent = () => {
    const { activeTab } = this.state;
    switch (activeTab) {
      case 'departments':
        return <div>Department content goes here...</div>;
      case 'evaluationComms':
        return <div>Evaluation Committees content goes here...</div>;
      case 'opportunities':
        return <div>Opportunities content goes here...</div>;
      default:
        return <div>Department content goes here...</div>;
    }
  }


  async deleteUser() {
    const { userData } = this.props;
    const newOwner = [];
    const selects = [];
    let confirmation = {
      "isConfirmed": false,
      "isDenied": false,
      "isDismissed": true,
      "dismiss": "cancel"
    };

    try {
      const assignments = await administrationService.getUserAssignments(userData.id);
      const { opportunities, evaluationComms, departments, users } = assignments;

      if (
        opportunities.responsible.length > 0 || 
        opportunities.proponent.length > 0 || 
        opportunities.coaches.length > 0 || 
        evaluationComms.length > 0 || 
        departments.length > 0
      ) {
        const selectOptions = users.map(user => ({
          value: user.id,
          label: user.title
        }));

        const renderSelectRow = (item, index, type, role = null) => {
          const idSelect = `select-${type}-${role ? role.toLowerCase() : index}-${index}`;
          selects.push({ id: idSelect, item, type, role });
          return (
            <tr key={idSelect}>
              {item.num ? <td>{item.num}</td> : null}
              <td>{item.title}</td>
              {role ? <td>{role}</td> : null}
              <td>
                <div id={idSelect} style={{ width: '200px' }} />
              </td>
            </tr>
          );
        };

        const committeesHtml = evaluationComms.map((comm, index) => renderSelectRow(comm, index, 'Committee'));
        const departmentsHtml = departments.map((dept, index) => renderSelectRow(dept, index, 'Department'));
        const responsibleHtml = opportunities.responsible.map((opportunity, index) =>
          renderSelectRow(opportunity, index, 'Opportunity', 'Responsible')
        );
        const coachHtml = opportunities.coaches.map((opportunity, index) =>
          renderSelectRow(opportunity, index, 'Opportunity', 'Coach')
        );
        const proponentHtml = opportunities.proponent.map((opportunity, index) =>
          renderSelectRow(opportunity, index, 'Opportunity', 'Proponent')
        );

        const htmlContainer = (
          <div id="container-delete-user" style={{ fontFamily: 'Arial, sans-serif' }}>
            <p style={{ marginBottom: '20px' }}>When you delete a user, you will be asked to reassign the following items:</p>
            <div>
              {evaluationComms.length > 0 && (
                <button id="tab-evaluationComms" className="btn btn-pill delete-user-btn">
                  Evaluation Committees
                </button>
              )}
              {departments.length > 0 && (
                <button id="tab-departments" className="btn btn-pill delete-user-btn">
                  Departments
                </button>
              )}
              {(opportunities.responsible.length > 0 || opportunities.coaches.length > 0) && (
                <button id="tab-opportunities" className="btn btn-pill delete-user-btn">
                  Opportunities
                </button>
              )}
              {opportunities.proponent.length > 0 && (
                <button id="tab-submital" className="btn btn-pill delete-user-btn">
                  Submittal
                </button>
              )}
            </div>

            {evaluationComms.length > 0 && (
              <div id="evaluationComms-section" className="table-container-delete-user" style={{ display: 'none' }}>
                <table className="styled-table">
                  <thead>
                    <tr>
                      <th>Committee Name</th>
                      <th>New Leader</th>
                    </tr>
                  </thead>
                  <tbody>{committeesHtml}</tbody>
                </table>
              </div>
            )}

            {departments.length > 0 && (
              <div id="departments-section" className="table-container-delete-user" style={{ display: 'none' }}>
                <table className="styled-table">
                  <thead>
                    <tr>
                      <th>Department Name</th>
                      <th>New Leader</th>
                    </tr>
                  </thead>
                  <tbody>{departmentsHtml}</tbody>
                </table>
              </div>
            )}

            {(opportunities.responsible.length > 0 || opportunities.coaches.length > 0) && (
              <div id="opportunities-section" className="table-container-delete-user" style={{ display: 'none' }}>
                <table className="styled-table">
                  <thead>
                    <tr>
                      <th>Opp #</th>
                      <th>Title</th>
                      <th>Role</th>
                      <th>New Owner/Coach</th>
                    </tr>
                  </thead>
                  <tbody>
                    {responsibleHtml}
                    {coachHtml}
                  </tbody>
                </table>
              </div>
            )}

            {opportunities.proponent.length > 0 && (
              <div id="submital-section" className="table-container-delete-user" style={{ display: 'none' }}>
                <table className="styled-table">
                  <thead>
                    <tr>
                      <th>Opp #</th>
                      <th>Title</th>
                      <th>New Contact</th>
                    </tr>
                  </thead>
                  <tbody>{proponentHtml}</tbody>
                </table>
              </div>
            )}
          </div>
        );

        confirmation = await Swal.fire({
          title: `Delete User: ${userData.firstName} ${userData.lastName}`,
          html: ReactDOMServer.renderToString(htmlContainer),
          showCancelButton: true,
          width: '800px',
          confirmButtonText: 'Transfer, then delete',
          cancelButtonText: 'Cancel',
          didOpen: () => {
            selects.forEach(select => {
              ReactDOM.render(
                <Select
                  menuPortalTarget={document.body}
                  styles={{ menuPortal: base => ({ ...base, zIndex: 99999 }) }}
                  onChange={res => {
                    const existing = newOwner.find(n => n.item.id === select.item.id && n.role === select.role && n.type === select.type);
                    if (existing) {
                      existing.res = res;
                    } else {
                      newOwner.push({
                        res,
                        item: select.item,
                        type: select.type,
                        role: select.role
                      });
                    }
                  }}
                  options={selectOptions}
                />,
                document.getElementById(select.id)
              );
            });



            const showSection = (sectionId) => {
              const sections = ['evaluationComms-section', 'departments-section', 'opportunities-section', 'submital-section'];
              sections.forEach(id => {
                const section = document.getElementById(id);
                if (section) section.style.display = 'none';
              });
              const selectedSection = document.getElementById(sectionId);
              if (selectedSection) selectedSection.style.display = 'block';
            };

            const addClickListener = (selector, sectionId) => {
              const element = document.getElementById(selector);
              if (element) {
                element.addEventListener('click', () => {
                  showSection(sectionId);
                  const buttons = document.querySelectorAll('.delete-user-btn');
                  buttons.forEach(btn => btn.classList.remove('btn-active'));
                  element.classList.add('btn-active');
                });
              }
            };

            addClickListener('tab-evaluationComms', 'evaluationComms-section');
            addClickListener('tab-departments', 'departments-section');
            addClickListener('tab-opportunities', 'opportunities-section');
            addClickListener('tab-submital', 'submital-section');

            const availableSections = ['evaluationComms-section', 'departments-section', 'opportunities-section', 'submital-section']
              .filter(sectionId => document.getElementById(sectionId) !== null);

            if (availableSections.length === 1) {
              showSection(availableSections[0]);
              document.querySelector(`#tab-${availableSections[0].split('-')[0]}`).classList.add('btn-active');
            } 
            
          },
          preConfirm: () => {
            if (newOwner.length !== selects.length) {
              Swal.showValidationMessage('Each item must be reassigned.');
              return false;
            }

            const allAssigned = selects.every(select =>
              newOwner.some(n => n.item.id === select.item.id && n.role === select.role && n.type === select.type)
            );

            if (!allAssigned) {
              Swal.showValidationMessage('Some items are not reassigned correctly.');
              return false;
            }

            return true;
          }
        });
      } else {
        confirmation = await Swal.fire({
          title: `Delete User: ${userData.firstName} ${userData.lastName}`,
          text: 'Are you sure you want to delete this user? This action cannot be undone.',
          showCancelButton: true,
          confirmButtonText: 'Yes, delete it!',
          cancelButtonText: 'No, keep it'
        });
      }

      if (confirmation.isConfirmed) {
        await administrationService.deleteUser(userData.id, newOwner);
        this.props.refreshUsers();
        await Swal.fire('Deleted!', 'The user has been deleted.', 'success');
      }
    } catch (error) {
      console.error('Error deleting user:', error);
      Swal.fire('Error', error.message, 'error');
    }
  }

  

  
  
  async updateAssignments(assignments, userData) {
    // for (const assignment of assignments) {
    //   const { item, res, type } = assignment;
    //   try {
    //     await this.updateAssignmentByType(item, res, type, userData);
    //   } catch (error) {
    //     console.error(`Error updating ${type}: ${error}`);
    //   }
    // }
  }
  
  
  async updateAssignmentByType(item, res, type, userData) {
    // let parseObject, updateFields;
  
    // switch (type) {
    //   case 'Opportunity':
    //     parseObject = Parse.Object.extend("Idea");
    //     updateFields = (objectToSave) => {
    //       objectToSave.set('responsible', res.value);
    //     };
    //     break;
    //   case 'Committee':
    //     parseObject = Parse.Object.extend("EvaluationCommittee");
    //     updateFields = (objectToSave) => {
    //       objectToSave.set('committeeLeader', res.value);
    //     };
    //     break;
    //   case 'Department':
    //     parseObject = Parse.Object.extend("IdeaDepartment");
    //     updateFields = (objectToSave) => {
    //       objectToSave.set('departmentLeader', res.value);
    //     };
    //     break;
    // }
  
    // const query = new Parse.Query(parseObject);
    // const objectToSave = await query.get(item.id, { useMasterKey: true });
    // updateFields(objectToSave);
    // await objectToSave.save(null, { useMasterKey: true });
  }
  

    reactivateAccount() {
      // const {userData} = this.props;
      // const user = userData
      // user.set("accountDisabled", false);
      // user.set("customUpdatedAt", new Date());

      // Parse.Object.saveAll([user], {useMasterKey: true}).then(() => {
      //   alert('The user was reactivated successfully.')
      //   this.props.refreshUsers()
      // }).catch((error) => {
      //   alert(error.message)
      // })
    }

    render() {
        const {secondaryEmail, canEdit, firstName, lastName, email, privileges, roles, coachRes, department, executionRes, supervisorSelected } = this.state
        const { ideaStage, userData } = this.props;
        const nowDate = this.getDate(Date())

        var isSameUser = false 
        // if (this.state.userData) {
        //   const currUserId = Parse.User.current().id
        //   const selectedUserId = this.state.userData.id
        //   isSameUser = currUserId == selectedUserId
        // }

        return(
            <div className="edit-user-details" >
                      <Form className="py-4"
                      onSubmit={this.onSubmit}
                      noValidate
                      >
                        
                        {/* VISIBILITY */}
                        <div >
                        <Row form >
                          {/* Left Part */}
                          <Col lg="3" className="mx-auto">
                            <Row form>
                              {/* Proponent */}
                              <Col md="12" className="form-group">
                                
                                <Row className="mt-2">
                                    <Col>
                                        <label htmlFor="firstName" className="georgia">Name: *</label>
                                        <FormInput
                                            id="categoryName"
                                            placeholder={'First Name'}
                                            value={firstName}
                                            onChange={this.setFirstName}
                                            className="insideFont"
                                        />
                                    </Col>
                                </Row>
                                <Row className="mt-2">
                                    <Col>
                                        <FormInput
                                        value={lastName}
                                        // style={{ minHeight: "80px" }}
                                        id="ideaQuestion"
                                        className="insideFont"
                                        placeholder="Last Name"
                                        onChange={(event) => this.changeLastName(event)}
                                        required>
                                        </FormInput>
                                    </Col>
                                </Row>
                        
                                <Row className="mt-4">
                                  <Col>       
                                    <label htmlFor="firstName" className="georgia">Roles: </label>
                                    { (!userData || userData.role !== 'murmuratto') ? <SelectPrivileges className="insideFont" evalType={'execution'} setResponsible={(res, idx) => this.changeRoles(res, idx)} selectedVal={roles}/> : <h6 style={{fontWeight: 500,  color: '#303030'}}>Murmuratto Support</h6> }
                                  </Col>
                                </Row>  
                              </Col>
    
                            </Row>
                          </Col>


                          {ideaStage == 1 && 
                            <Col lg="3" className="mx-auto">
                              <Row form>
                                <Col md="12" className="form-group">
                                    <Row className="mt-2">
                                        <Col>
                                            <label htmlFor="firstName" className="georgia">Company Information: *</label>
                                            <DepartmentSelect className="insideFont" evalType={'execution'} setResponsible={(res, idx) => this.changeDepartment(res, idx)} selectedVal={department} isMurmurattoUser={(userData && userData.role === 'murmuratto')}/>
                                        </Col>
                                    </Row>
                                    <Row className="mt-2">
                                        <Col>
                                            <SupervisorSelect className="insideFont" evalType={'execution'} setResponsible={(res, idx) => this.changeSupervisor(res, idx)} selectedVal={supervisorSelected} isMurmurattoUser={(userData && userData.role === 'murmuratto')}/>
                                        </Col>
                                    </Row>
                                    <Row className="mt-4">
                                      <Col>
                                          <label htmlFor="firstName" className="georgia">Additional Privileges: </label>
                                          <SelectPrivileges className="insideFont" evalType={'execution'} type={'privileges'} setResponsible={(res, idx) => this.changePrivileges(res, idx)} selectedVal={privileges}/>
                                      </Col>
                                  </Row>
                                </Col>
                              </Row>
                            </Col>
                          }

           

                          {ideaStage == 1 && 
                            <Col lg="3" className="mx-auto">
                              <Row form>
                                <Col md="12" className="form-group">
                                    <Row className="mt-2">
                                        <Col>
                                            <label htmlFor="firstName" className="georgia">User Information: *</label>
                                            <FormInput
                                                id="categoryName"
                                                placeholder={'Email'}
                                                value={email}
                                                onChange={this.setEmail}
                                                className="insideFont"
                                            />
                                        </Col>
                                    </Row>
                                    {canEdit ?
                                    <Row className="mt-2">
                                        <Col>
                                            <Button onClick={() => this.sendResetEmailToUser(email, true)}>Reset Password</Button>
                                        </Col>
                                    </Row>
                                      : null}
                                    <Row className="mt-4" >
                                        <Col style={{marginTop: '3.5%'}}>
                                            <label htmlFor="firstName" className="georgia">Secondary Email: (Optional)</label>
                                            <FormInput
                                                id="secondaryEmail"
                                                placeholder={'Email'}
                                                value={secondaryEmail}
                                                onChange={this.setSecondaryEmail}
                                                className="insideFont"
                                            />
                                        </Col>
                                    </Row>
                                    
                                    { this.state.accountDisabled && 
                                    <Row className="mt-2">
                                        <Col>
                                            <Button onClick={() => this.reactivateAccount()}>Reactivate Account</Button>
                                        </Col>
                                    </Row>
                                    }
                                </Col>
                              </Row>
                            </Col>
                          }

                          {/* Right Part */}
                          

                          {ideaStage == 2 && 
                            <Col lg="6">
                              <Row className="mt-4">
                                  <Col md="6">
                                    <label htmlFor="firstName" className="georgia">Choose how to proceed: </label>
                                    <Row>
                                      <Col>
                                      {this.getIcon(this.state.selectedStatus, 'Black')}
                                          <div className="mr-auto" style={{width: '100%', backgrounColor: 'black'}}>
                                            <h6 style={{fontWeight: 500,  color: '#303030'}}>{this.state.selectedStatus}</h6>
                                          </div>
                                      </Col>
                                    </Row>
                                  </Col>
                                  <Col md="6">
                                    <Row className="mt-2">
                                      <Col>
                                        <label htmlFor="firstName" className="georgia">Employee Response Date</label>
                                        <h6 style={{fontWeight: 500,  color: '#303030'}}>{nowDate}</h6>
                                      </Col>
                                    </Row>
                                    <Row className="mt-2">
                                    <Col>
                                        <label htmlFor="firstName" className="georgia">Idea Status</label>
                                        <Row>
                                          <Col md="7">
                                            <h6 style={{fontWeight: 500,  color: '#303030'}}>{'Pending'}</h6>
                                          </Col>
                                          <Col className="mb-auto" md="1">
                                            {/* <div className="my-auto" style={{backgroundColor: '#1DE334', height: 16, width: 16, borderRadius: 8}}></div> */}
                                            {/* { timingWording == "On-Time"? 
                                            <GreenIcon style={{height: 16, width: 16}}></GreenIcon>
                                            : */}
                                            <RedIcon style={{height: 16, width: 16}}></RedIcon>
                                            {/* } */}
                                          </Col>
                                          <Col md="1" className="mb-auto">
                                            <a id={"TooltipResponseInfo2"} className="text-right" style={{ color: 'inherit'}} onClick={() => {
                                                const myCopy = this.state.responseInfo2
                                                myCopy = !myCopy
                                                this.setState({responseInfo2: myCopy})
                                            }}>
                                                
                                                <InfoIcon style={{height: 16, width: 16}}></InfoIcon>
                                                
                                            </a>
                                          </Col>
                                         
                                          <Tooltip
                                            open={this.state.responseInfo2}
                                            target={"#TooltipResponseInfo2"}
                                            id={"TooltipResponseInfo2"}
                                            toggle={() => {this.toggle()}}
                                            >
                                            Type Category Description. Lorem ipsum dolor sit amet, consectetuer adipi- scing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volut-!
                                          </Tooltip>
                                      </Row>

                                      </Col>
                                    </Row>
                                    
                                  </Col>
                              </Row>

                              {/* Subject Matter Comments */}
                              <Row form className="mt-4">
                                <Col md="12" className="form-group">
                                  <label htmlFor="firstName" className="georgia">Subject-Matter Expert Comments:</label>
                                  <h6 style={{fontWeight: 500,  color: '#303030'}}>{this.state.comment}</h6>
                                </Col>
                              </Row>

                              <Row form className="mt-4">
                                <Col md="12" className="form-group">
                                  <label className="georgia">{'Estimate economic/output impact'}</label>
                                  <Row>
                                    <Col>
                                      <h6 style={{fontWeight: 500,  color: '#303030'}}>{'$'+this.state.expectedReturn}</h6>
                                    </Col>
                                    <Col md="4">
                                      <h6 style={{fontWeight: 500,  color: '#303030'}}>{this.state.timeUnit}</h6>
                                    </Col>
                                    <Col md="4">
                                      <Switch 
                                        isOn={this.state.recurringImpact}
                                        disabled
                                        onColor="#633FDA"
                                        title="Recurring Impact"
                                      />
                                    </Col>
                                  </Row>
                                </Col>
                              </Row>
                              <Row>
                                  
                              </Row>

                              { this.state.selectedStatus !== "Do not Pursue" &&
                              <Row form className="mt-4">
                                <Col md="12" className="form-group">
                                  <h6 style={{fontWeight: 500,  color: '#303030'}}>{'Choose an Idea Owner *'}</h6>
                                    <ExecutionSelectNew className="insideFont" evalType={'execution'} setResponsible={(res, idx) => this.changeResponsible(res, idx)} selectedVal={executionRes}/>
                                  <br/>
                                  <h6 style={{fontWeight: 500,  color: '#303030'}}>{'Choose an Idea Coach'}</h6>
                                    <ExecutionSelectNew className="insideFont" evalType={'coach'} setResponsible={(res, idx) => this.changeCoach(res, idx)} selectedVal={coachRes}/>
                                </Col>
                              </Row>
                              }
                            </Col>
                          }
                          
                        </Row>
                       
                        <Row className="mt-2">
                            <Col md={canEdit ? "2" : "1"} className="ml-auto">
                                <Row>

                                    <Col md="3" className={canEdit ? "ml-auto" : "mr-auto"}>
                                        { <AcceptIcon className="functionalButton" style={{height: 34, width: 34}} onClick={() => canEdit ? this.editUser() : this.saveUser()}></AcceptIcon>}
                                    </Col>

                                    {canEdit && <Col md="3" className="mr-auto">
                                       { !isSameUser && <CancelIcon className="functionalButton" style={{height: 34, width: 34}} onClick={() => this.deleteUser()}></CancelIcon>}
                                    </Col> }
                                </Row>
                            </Col>
                        </Row>
    
        
                      
                        </div>
                        </Form>
  
                  </div>
          );
    }
}




export default withTranslation()(EditUserForm);